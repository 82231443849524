import React from "react"

const Sidenote = (props: {
  id: string
  extraCss?: string
  note?: React.ReactChild | React.ReactFragment | React.ReactPortal
}) => (
  <>
    <label htmlFor={props.id} className="margin-toggle sidenote-number"></label>
    <input type="checkbox" id={props.id} className="margin-toggle" />
    <span className={"sidenote " + props.extraCss}>{props.note}</span>
  </>
)

export default Sidenote
