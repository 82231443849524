exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-scatter-3-d-tsx": () => import("./../../../src/pages/scatter3d.tsx" /* webpackChunkName: "component---src-pages-scatter-3-d-tsx" */),
  "component---src-pages-speaking-and-publications-tsx": () => import("./../../../src/pages/speaking-and-publications.tsx" /* webpackChunkName: "component---src-pages-speaking-and-publications-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-mdx-blog-post-tsx": () => import("./../../../src/templates/mdx-blog-post.tsx" /* webpackChunkName: "component---src-templates-mdx-blog-post-tsx" */)
}

